.single-team,
.single-team-info,
.sti-details {
    display: flex;
}

.search-team,
.single-team {
    align-items: center;
}

.teams-container {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    height: fit-content;
    padding-bottom: 25px;
}

.search-team {
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.search-team-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 15px;
}

.search-team input[type="text"] {
    width: 175px;
    height: 32px;
    border-radius: 3px;
    border-bottom: 2px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0,0,0,0.25);
    padding: 0 12px;
    margin-left: 7px;
    font-size: 17px;
}

.search-team input[type="text"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.all-teams-filter-cont {
    position: absolute;
    right: 0;
    top: 0;
}

.all-teams,
.all-teams-active {
    display: flex;
    align-items: center;
    height: 32px;
    width: fit-content;
    padding: 0 3px;
    font-size: 13px;
    cursor: pointer;
}

.all-teams-active {
    color: var(--dark-yellow);
}

.all-teams-checked,
.all-teams-unchecked {
    margin-right: 3px;
    font-size: 20px;
}

.team-details-cont {
    width: 100%;
    height: fit-content;
}

.single-team-cont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 20px;
}

.single-team {
    flex-direction: column;
    height: fit-content;
    float: left;
    width: 220px;
    padding: 10px;
    background-color: rgba(0,0,0,0.35);
    border: 1px solid transparent;
    box-shadow: 7px 7px 12px black;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
}

.single-team-logo-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 140px;
    margin-bottom: 10px;
}

.single-team-logo {
    height: 105px;
    transition: transform .5s ease;
}

.single-team-name {
    background: var(--dark-blue);
    width: fit-content;
    margin-top: -9px;
    margin-bottom: 12px;
    padding: 0 7px;
    font-size: 14px;
    font-weight: bold;
}

.single-team-name-line {
    width: 100%;
    border: .5px solid rgba(255, 255, 255, 0.25);
}

.single-team-info {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 105px;
    margin-top: 5px;
}

.sti-details {
    text-align: left;
    margin-bottom: 5px;
    font-size: 12px;
}

.sti-details span {
    min-width: 85px;
    text-align: left;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
}

.no-teams-found {
    margin-top: 52px;
    font-size: 17px;
}

.chosen-team {
    border-color: rgba(255, 255, 255, 0.7);
}

.no-team-selected-icon {
    font-size: 3em;
}

/* FOTBOLL PLAY INFO */
.team-channels-cont {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.subscription-info-cont {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 3px;
}

.subscription-info-name {
    font-size: 15px;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    color: var(--light-blue);
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.subscription-info {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 3px 7px;
}

.subscription-info-title {
    width: 45px;
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
}

.subscription-info-value {
    font-size: 12px;
}

.add-merchant-cont {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-width: 480px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}

.add-merchant-form {
    flex-grow: 1;
}

.add-merchant-title {
    text-align: center;
    font-size: 17px;
    margin-bottom: 15px;
}

/* HOVER */
.single-team:hover {
    border-width: 1px;
}

.single-team:hover > 
.single-team-logo-cont >
.single-team-logo {
    transform: scale(1.1);
}

.league-filter:hover {
    border-bottom: 2px solid var(--dark-yellow);
}

.all-teams:hover {
    color: var(--dark-yellow);
}
.team-details-info-cont,
.tdi-name,
.tdi-cont,
.tdi-coach,
.tdi-details {
    display: flex;
}

.team-details-info-cont {
    height: 100%;
    padding-top: 5px;
}

.logo-and-goto-cont {
    width: 195px;
    margin-right: 32px;
}

.tdi-logo-cont {
    position: relative;
    width: 195px;
    height: fit-content;
    text-align: center;
}

.tdi-logo {
    max-height: 100%;
    max-width: 100%;
}

.goto-btn-cont {
    margin-top: 32px;
    margin-bottom: 12px;
    width: 100%;
}

.goto-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-bottom: 7px;
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    text-decoration: none;
}

.goto-btn-cont a:visited {
    color: unset;
}

.tdi-name {
    margin-bottom: 17px;
    font-size: 1.7em;
    font-weight: bold;
}

.tdi-cont {
    flex-direction: column;
    width: 100%;
}

.tdi-cont button {
    width: fit-content;
    margin-top: 10px;
}

/* Edit Team */
.edit-team-cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    padding: 5px 0;
    gap: 10px;
}

.edit-team-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 25px;
}

.edit-team {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.et-value {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
}

.et-logo-and-btn {
    width: 200px;
    height: 200px;
    margin-top: 5px;
    margin-bottom: 30px;
    text-align: center;
}

.et-logo-and-btn div {
    padding: 7px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.et-logo-and-btn img {
    height: 150px;
}

.et-logo-and-btn button{
    width: 100%;
    margin-top: 5px;
}

/* HOVER */
.goto-link:hover {
    background: var(--light-blue);
}
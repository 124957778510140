.club-config-main {
    position: relative;
    display: flex;
    gap: 10px;
    height: 100%;
    width: fit-content;
    max-width: 1080px;
    padding: 20px;
    padding-left: 0;
    background-color: #101525;
    border-radius: 2px;
}

.club-config-cont {
    margin: 0 auto;
    height: 100%;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;
    border-radius: 2px;
    padding-left: 5px;
}

.club-config-option-cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 180px;
}

.club-config-option-cont.admin {
    padding: 20px 10px;
    height: fit-content; 
    /* background-color: #0b0e1a; */
    /* background-color: #0b0f1c; */
}

.club-config-option {
    display: flex;
    gap: 5px;
    padding: 7px;
    font-size: 13px;
    cursor: pointer;
}

.club-config-option.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    border-radius: 2px;
    font-weight: 600;
}

.club-config-section-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.club-config-section {
    width: 100%;
    padding: 0 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.club-config-section-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 20px;
}

.club-config-section-title.space-between {
    justify-content: space-between;
}

.club-config-data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 12px;
    min-height: 30px;
}


.club-config-data.align-start {
    align-items: flex-start;
}

.club-config-data.align-start.margin-top {
    margin-top: 17px;
}

.club-config-data-title {
    width: 35%;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
}

.advanced-color .club-config-data-title {
    min-width: 175px;
}

.italic {
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);
}

.club-config-data-value {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 450px;
}

.club-config-data-value.color {
    display: flex;
    align-items: center;
    gap: 15px;
}

.color-value-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.club-config-edit {
    color: var(--light-blue);
    cursor: pointer;
    font-size: 13px;
}

.data-color {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    min-width: 100px;
}

.data-color-box {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 28px;
    height: 30px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: -2px;
    border: 1px solid transparent;
}

.data-color-box.active {
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    cursor: pointer;
}

.data-color-box::-webkit-color-swatch {
    border: none;
}
.data-color-box::-moz-color-swatch {
    border: none;
}

.config-color-box {
    width: 21px;
    height: 21px;
}

.config-color-box.hide {
    display: none;
}

.club-config-no-value {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
}

.config-reset-btn {
    color: var(--light-blue);
    cursor: pointer;
    font-size: 13px;
}

.club-config-dot {
    height: 2px;
    width: 2px;
    margin-top: 2px;
    background-color: white
}

.background-color-info {
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}

.background-light-mode-info {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
}

.club-config-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    width: 100%;
}

.club-config-hero-img {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.club-config-hero-img img {
    width: 100%;
    object-fit: cover;
}

.desktop .active-box {
    position: absolute;
    top: 0;
    width: 100%;
    /* height: 40%; */
    border: 1px solid white;
}

.desktop .first-shade {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.desktop .second-shade {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.mobile .active-box {
    position: absolute;
    top: 0;
    margin: 0 auto;
    height: 100%;
    width: 60%;
    border: 1px solid white;
}

.mobile .first-shade {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.mobile .second-shade {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.club-config-hero-logo {
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    height: 42px;
}

.club-config-hero-logo-img {
    height: 100%;
}

.club-config-hero-change {
    display: flex;
    align-items: center;
    gap: 12px;
    visibility: hidden;
}

.hero-img {
    width: 450px;
}

.club-config-hero-change.active {
    visibility: visible;
}

.hero-image-size-cont {
    display: flex;
    gap: 15px;
    margin-bottom: 3px;
}

.hero-image-size {
    font-size: 12px;
    color: var(--light-blue);
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.hero-image-size.active {
    color: var(--dark-yellow);
}

.club-config-controls {
    width: 100%;
    background-color: #101525;
    z-index: 1;
}

.club-config-button-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.club-config-button-cont button {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 25%;
    max-width: 200px;
    padding: 8px 5px;
    border: 1px solid transparent;
    border-radius: 2px;
    font-weight: 600;
    cursor: pointer;
}

input.new-color-input {
    border: 1px solid transparent;
    border-radius: 2px;
    width: 80px;
    padding-left: 5px;
}

.edit-configuration,
.copy-preview-link,
.open-preview-link {
    background-color: #01a2ff33;
    color: #0292e6;
}

.discard-configuration {
    background-color: #e2220548;
    color: #d2430e;
}

.submit-configuration {
    background-color: #2cc30a45;
    color: #2DC30A;
}

.club-config-team-logo {
    margin: 0 auto;
    width: 100px;
    margin-bottom: 15px;
}

.hero-position-cont {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.hero-position-part {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 35px;
}

.club-config-dropdown-toggle {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    color: var(--light-blue);
    font-weight: 600;
    cursor: pointer;
}

.club-config-dropdown-toggle svg {
    font-size: 15px;
    margin-bottom: -1px;
    transition: transform .1s linear;
}

.show .club-config-dropdown-toggle svg {
    transform: rotate(90deg)
}

.colors-overrides-cont {
    width: 100%;
    margin-top: 17px;
}

.colors-overrides-title {
    display: flex;
    align-items: center;
    gap: 25px;
    font-weight: 600;
}

.colors-overrides-list {
    width: 100%;
    height: 0;
    margin-top: 15px;
    overflow: hidden;
    transition: height .1s linear;
}

.show .colors-overrides-list {
    height: 520px;
}

.club-config-team-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    min-width: 200px;
    overflow-y: scroll;
}

.club-config-team-single {
    width: 100%;
    padding: 5px 0;
    padding-left: 15px;
    border-left: 3px solid #23459c;
}

.club-config-team-single.superettan {
    border-color: #005123;
}

.club-config-team-single.legacy {
    border-color: rgb(100, 100, 100);
}

.club-config-team-select {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;
    padding: 3px;
    border-radius: 1px;
    font-size: 12px;
    color: rgba(255, 255, 255, .75);
    cursor: pointer;
}

.club-config-team-select.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-weight: 600;
}

.club-config-team-select img {
    width: 28px;
    cursor: pointer;   
}

.club-config-start-message {
    margin: 0 auto;
    margin-top: 75px;
    height: fit-content;
    font-size: 14px;
}

.hero-position-input-cont {
    width: 270px;
    position: relative;
}

.hero-position-indicator {
    position: absolute;
    top: 25px;
    font-size: 10px;
    color: rgba(255, 255, 255, .5);
}

.hero-position-indicator.start {
    left: 2px;
}

.hero-position-indicator.mid {
    left: 0;
    right: 0;
}

.hero-position-indicator.end {
    right: -2px;
}

.hero-position-input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    margin-bottom: 7px;
    background-color: var(--dark-yellow);
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}

.hero-position-input.vertical {
    transform: rotate(180deg); /* Flip the slider thumb direction */
}
  
.hero-position-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid var(--dark-yellow);
    background-color: #101525;
    cursor: pointer;
}

.hero-position-input:not(.disabled):hover::-webkit-slider-thumb {
    width: 17px;
    height: 17px;
}

.hero-position-input.disabled {
    background-color: var(--light-blue);
    cursor: unset;
}

.hero-position-input.disabled::-webkit-slider-thumb {
    cursor: unset;
}

.hero-position-input.disabled::-webkit-slider-thumb {
    border-color: var(--light-blue);
}

.club-config-message {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 32px;
}

input.hero-message-input {
    padding: 5px;
    height: 32px;
    width: 300px;
    border-radius: 2px;
    border: 1px solid transparent;
}

.grey-color-text {
    color: rgba(255, 255, 255, 0.7);
}

.colors-overrides-disclaimer {
    margin-bottom: 12px;
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}

.contrast-warning-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 7px;
    border-radius: 2px;
    border: 1px solid rgba(255, 123, 0, 0.7);
}

.contrast-warning-title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    color: rgb(255, 125, 0);
    font-style: italic;
}

.contrast-warning-title svg {
    font-size: 20px;
}

.contrast-warning-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}


/* Club config pre and post roll */
.roll-clip-list {
    height: fit-content;
    width: 100%;
}

.roll-clip-single-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.roll-clip-single {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 12px;
    background-color: #0b0e1a;
    /* background-color: #0b0f1c; */
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    font-size: 12px;
}

.roll-clip-none {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.7);
}

.roll-clip-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.club-config-upload-front {
    height: calc(100% - 50px);
    width: 100%;
}

.club-config-upload {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.club-config-upload-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 2px;
    flex-grow: 1;
    margin-bottom: 12px;
    overflow-y: scroll;
}



/* Collections */
.config-collection-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 2px;
    background-color: #0c0e18;
}

.add-collection-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 25px;
}

.editing-collection {
    position: relative;
    padding: 7px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.available-collection-list {
    width: 100%;
}

.add-collection-single {
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    height: 42px;
    width: calc(50% - 7px);
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 5px 10px;
    background-color: #0c0e18;
    color: rgba(255, 255, 255, 0.7);
}

.add-collection-title-name {
    display: flex;
    flex-grow: 1;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
}

input.collection-title-input {
    padding: 5px;
    height: 32px;
    width: 150px;
    border-radius: 2px;
    border: 1px solid transparent;
}

.collection-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
}

.collection-single-title {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
    max-width: 450px;
}

.collection-title-name {
    text-align: left;
    width: 150px;
    font-size: 13px;
    text-transform: uppercase;
}

.collection-custom-title {
    color: var(--light-blue);
}

.collection-single-video {
    width: 65px;
    text-align: left;
    font-size: 12px;
    font-style: italic;
    color: rgb(255, 125, 0);
}

.collection-custom-title-edit {
    display: flex;
    align-items: center;
    gap: 7px;
}

.collection-single-remove {
    width: 25px;
    font-size: 15px;
    margin-top: 2px;
    cursor: pointer;
    visibility: hidden;
}

.collection-add {
    font-size: 17px;
    margin-top: 3px;
    cursor: pointer;
}

.custom-title {
    font-size: 12px;
    font-style: italic;

}

.collection-single-remove.show {
    visibility: visible;
}

.available-header-title {
    font-size: 12px;
    font-weight: 600;
}

.reorder-collection {
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.7);
}

.submitted-change-info {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: left;
    font-size: 12px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

.submitted-change-info svg {
    font-size: 14px;
    margin-top: -1px;
}

/* HOVER */
.club-config-option:not(.active):hover,
.club-config-edit:hover,
.config-reset-btn:hover,
.club-config-dropdown-toggle:hover {
    filter: brightness(80%);
}
button.edit-configuration:hover,
button.copy-preview-link:not(.disabled):hover,
button.open-preview-link:hover {
    background-color: #01a2ff4c;
    color: #0292e6;
    border: 1px solid transparent;
}
.hero-image-size:not(.active):hover {
    color: rgba(255, 255, 255, 0.7);
}

button.discard-configuration:hover {
    background-color: #e222056a;
    color: #d2430e;
    border: 1px solid transparent;
}

button.submit-configuration:hover {
    background-color: #2cc30a64;
    color: #2DC30A;
    border: 1px solid transparent;
}

.club-config-team-select:hover {
    filter: brightness(80%);
}
.collection-single-remove:hover,
.collection-add:hover {
    color: var(--dark-yellow);
}
.editing-collection .config-collection-single:hover {
    filter: brightness(80%);
}
.compilation-list-cont,
.compilation-single-clip,
.list-duration-description,
.compilation-list-btn-cont,
.length-duration,
.compilation-details-edit,
.compilation-unlisted,
.compilation-edit-btn {
    display: flex;
}

.compilation-single-clip,
.list-duration-description,
.length-duration,
.compilation-unlisted {
    align-items: center;
}

.compilation-container {
    width: 100%;
}

.player-list-cont {
    position: relative;
    width: 100%;
    display: flex;
    height: 0;
    padding-top: 46%;
}

.player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 100%;
    padding-right: 5px;
}

.clip-list {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30%;
    height: 100%;
}

.compilation-list-cont {
    display: block;
    width: 100%;
    height: calc(100% - 90px);
}

.compilation-single-clip {
    white-space: nowrap;
    border: 1px solid var(--dark-blue);
    background-color: rgb(50, 60, 70);
    height: 35px;
    cursor: grab;
}

.compilation-single-clip.active {
    background-color: var(--light-blue);
    color: black;
}

.draggable-cont {
    position: relative;
    height: 100%;
    width: 100%;
}

.droppable-cont {
    position: relative;
    height: 100%;
}

.drag-to-reorder {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.25);
}

.list-duration-description {
    height: 100%;
    width: 90%;
    padding-left: 7px;
    padding-right: 3px;
    cursor: pointer;
}

.list-tag {
    display: flex;
    align-items: center;
    margin-left: 7px;
    font-size: 17px;
}

.list-desc {
    width: 90%;
    margin-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remove-clip-icon {
    cursor: pointer;
    height: 100%;
    width: 10%;
    padding: 9px 3px;
}

.compilation-list-btn-cont {
    flex-direction: column;
}

.length-duration {
    flex-direction: column;
    justify-content: center;
    margin: 7px 0;
    gap: 7px;
    color: var(--dark-yellow);
}

.compilation-preview,
.compilation-clear {
    font-size: 13px;
}

.video-editing-controls {
    display: flex;
    flex-direction: column;
    height: 150px;
    margin-top: 25px;
    margin-bottom: 21px;
    padding: 3px 10px;
    background: #101525;
    box-shadow: 0 0 10px black;
}

.oc-game-details {
    height: 100%;
    display: flex;
    align-items: center;
}

.score-dash{
    margin: 0 3px;
}

.compilation-unlisted {
    margin-top: 7px;
    margin-left: -4px;
}

.compilation-unlisted label {
    display: flex;
    cursor: pointer;
}

.compilation-desc-title {
    margin-bottom: 5px;
}

.comp-checkbox-cont {
    display: flex;
    gap: 17px;
}

.comp-checkbox {
    font-size: 17px;
    width: 25px;
}

.error-msg-cont {
    display: flex;
    align-items: center;
    min-height: 45px;
}

.compilation-edit-btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.compilation-save {
    display: flex;
    justify-content: center;
}

.compilation-clips-cont {
    margin-top: 17px;
}

.compilation-single-logo {
    height: 24px;
    margin: 0 7px;
}

button.sort-chronologically-btn {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0px;
    font-size: 12px;
    border: none;
    cursor: pointer;
}

button.sort-chronologically-btn.disabled {
    filter: brightness(50%);
    cursor: unset;
}

.sort-chronologically-btn svg {
    font-size: 18px;
}

.active-auto-sort {
    color: var(--light-blue);
}

.compilation-loading-screen {
    width: 100%;
    aspect-ratio: 16/9;
}

/* HOVER */
.compilation-preview:focus,
.compilation-clear:focus {
    background: none;
}
.compilation-preview:hover{
    background: var(--light-blue);
}
.remove-clip-icon:hover {
    color: var(--dark-yellow);
}
button.sort-chronologically-btn:hover {
    background-color: unset;
    color: white;
}
button.sort-chronologically-btn:not(.disabled):hover {
    filter: brightness(80%);
}
button.sort-chronologically-btn.disabled:hover {
    filter: brightness(50%);
}
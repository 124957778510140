.data-info-cont {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 15px 12px;
    background-color: #101525;
    border-radius: 2px;
    padding-bottom: 20px !important;
    margin-bottom: 25px !important;
}

.data-info-head-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 12px;
    padding: 2px 0;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
}

.data-info {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 18px 2px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.data-info.no-data {
    padding: 5px 2px;
    border-bottom: unset;
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);
}

.data-info.column {
    flex-direction: column;
}

.data-info-title-and-value {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.data-info.column {
    flex-direction: column;
}

.data-info-title-and-value {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.data-info-title {
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
    font-size: 11px;
    text-transform: uppercase;
    color: var(--grey);
    white-space: nowrap;
}

.data-info-value {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 500;
    padding-right: 2px;
    text-align: right;
    padding-left: 10px;
    line-break: anywhere;
}

.data-info-head-title.between {
    justify-content: space-between;
}

.data-info-head-title.margin-bottom {
    margin-bottom: 25px;
}

.table-header-cont {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    background-color: #101525;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 5px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: var(--grey);
    z-index: 1;
}

.table-header-cont.sticky {
    position: sticky;
    top: 0;
}

.table-header-sort {
    cursor: pointer;
}

.table-header-sort-icon {
    margin-bottom: -2px;
    margin-left: 3px;
    font-size: 14px;
}

.blank-page-bg {
    position: absolute;
    bottom: 0;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--dark-blue);
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .data-info {
        padding: 14px 2px;
    }
    .data-info-title {
        font-size: 11px;
    }
    .data-info-value {
        font-size: 12px;
        font-weight: 500;
        padding-right: 0;
        padding-top: 1px;
    }
}

@media screen and (max-width: 480px) {
    .data-info-cont {
        margin-bottom: 15px !important;
    }
    .data-info-head-title {
        font-size: 13px;
        margin-bottom: 7px;
    }
}